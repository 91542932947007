import dynamic from 'next/dynamic'
import Head from 'next/head'
import firebase_app from '../lib/firebaseConfig'

import { getFirestore, collection, query, where, getDocs, orderBy } from "firebase/firestore";

import { descripion } from '../constants/seo'
import LoadingComponent from '../components/shared/loadingComponent'

const HeroHomeComponent = dynamic(() => import('../components/home/heroHomeComponent'), {
    loading: () => <LoadingComponent />,
})
const HighlightesComponent = dynamic(() => import('../components/home/highlightesHomeComponent'), {
    loading: () => <LoadingComponent />,
})
const CardCurrentItems = dynamic(() => import('../components/cardsCoins/cardCurrentItems'), {
    loading: () => <LoadingComponent />,
})
const PagaeLoad = dynamic(() => import('../components/animations/pageLoad'), {
    loading: () => <LoadingComponent />,
})


const db = getFirestore(firebase_app);
export async function getStaticProps() {
    try {
        const citiesRef = collection(db, "coinData");

        const q = query(citiesRef, where("highlighted", "==", "y"), where("archive", "==", "n"), orderBy("country"), orderBy("year"));
        const querySnapshot = await getDocs(q);
        let itemsArray1 = []
        await querySnapshot.forEach((doc) => {
            itemsArray1.push({ ...doc.data() })
        });
        let itemsArray = JSON.stringify(itemsArray1)
        return {
            props: { itemsArray }
        }
    } catch (e) {
        console.log('check error: ' + e)
        return {
            props: { itemsArray: [] }
        }
    }
}

export default function Home(props) {

    const title = 'Home | Fl Gold Coin Exchange '

    return (< div className='homeBgImg'>
        <Head>
            <title>{title}</title>
            <meta name="description" content={descripion} />
            <meta keywords="fl gold, goldcoin exchange, Fl gold coin exchange, florida coins, coinomania, tampa coin exchange, gold coins tampa, fl coin, buy coins" />
            <link rel="canonical" href={`${process.env.siteUrl}/`} />
            <meta property="og:url" content={`${process.env.siteUrl}/`} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={descripion} />
            <meta property="og:image" content={`${process.env.siteUrl}/images/facebook.jpg`} />
            <meta name="AdsBot-Google" content="index" />
            <meta name="robots" content="index, follow" />
        </Head>
        {/*<PagaeLoad>*/}
        {/*    <HeroHomeComponent />*/}
        {/*</PagaeLoad>*/}
        <PagaeLoad>
            <HighlightesComponent />
        </PagaeLoad>
        <PagaeLoad>
            <CardCurrentItems
                coinLink={'currentCoin'}
                data={props}
            />
        </PagaeLoad>
    </div>
    )
}
