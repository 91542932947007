import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner';
import style from './Shared.module.css'

export default function LoadingComponent() {
    return (<div className='sectionOrArticleTB'>
        <Container>
            <Row >
                <Col className='text-center' >
                    <Spinner
                        animation="border"
                        role="status"
                        size="xl"
                        aria-hidden="true"
                        className={style.loadingSpinnerColor}
                    >
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </Col>
            </Row>
        </Container>
    </div>)
}